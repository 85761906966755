import { render, staticRenderFns } from "./container.vue?vue&type=template&id=181d535a"
import script from "./container.vue?vue&type=script&lang=js"
export * from "./container.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports